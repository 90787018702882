import React, { useState } from "react"
import * as Yup from "yup"
import { ErrorMessage, Field, Form, Formik } from "formik"

import dbServices from "../../services/firebase/db"

import "./index.scss"
import { navigate } from "gatsby"
const UnsubscribeForm = () => {
  const [showMessage, setShowMessage] = useState(false)
  const [counter, setCounter] = useState(3)

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("*Must be a valid email address")
      .max(100, "*Email must be less than 100 characters")
      .required("*This field is required"),
  })
  return (
    <div className="unsubscribe-container">
      <div className="unsubscribe-form row">
        <div className="clo-md-12 unsubscribe-text">
          To unsubscribe from our newsletter please type your email then click
          the <span>"unsubscribe"</span> button
        </div>
        <div className="clo-md-12">
          <Formik
            validationSchema={validationSchema}
            initialValues={{ email: "" }}
            onSubmit={async (
              values,
              { setSubmitting, resetForm, setErrors }
            ) => {
              setSubmitting(true)
              const res = await dbServices.editContact(values.email.trim(), {
                subscribedToNewsletter: false,
              })

              if (res.success) {
                resetForm({})
                setShowMessage(true)
                let count = counter
                const counterInterval = setInterval(() => {
                  setCounter(count--)
                }, 1000)
                setTimeout(() => {
                  clearInterval(counterInterval)
                  navigate("/")
                }, 3000)
              } else {
                setErrors({ email: res.error })
              }
              setSubmitting(false)
            }}
          >
            {() => (
              <Form>
                <div className="form-container row">
                  <div className="edit-input col-md-9">
                    <Field type="text" name="email" className="form-control" />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="error-message"
                    />
                  </div>
                  <div className="btn-submit-container col-md-3">
                    <button type="submit" className="btn-submit mx-1">
                      unsubscribe
                    </button>
                  </div>
                  {showMessage ? (
                    <p className="success-msg">
                      You have been <span>successfully</span> unsubscribed from
                      our newsletter... <span>{counter}</span>
                    </p>
                  ) : null}
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  )
}

export default UnsubscribeForm
