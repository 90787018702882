import React from "react"

import FooterMain from "../../footer/footer-main"
import HeaderMain from "../../header/header-main"
import Seo from "../../seo"
import ZendeskButton from "../../zendesk"

import "./layout.css"
const Layout = ({ pageTitle, children }) => {
  return (
    <div className="container-fluid bg">
      <Seo title={pageTitle} />
      <div className="container mobilelayoutcontainer">
        <HeaderMain />
        {children}
        <FooterMain />
      </div>
      <ZendeskButton />
    </div>
  )
}

export default Layout
