import React from "react"

import Layout from "../components/layout/t1/layout"
import UnsubscribeForm from "../components/unsubscribe"

const Unsubscribe = () => {
  return (
    <Layout pageTitle="Unsubscribe">
      <UnsubscribeForm />
    </Layout>
  )
}

export default Unsubscribe
